import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import api from '../../services/api';
import cep from 'cep-promise';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TopPage from '../../components/TopPage';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Select from '../../components/Select';
import Textarea from '../../components/Textarea';
import Alert from '../../components/Alert';
import Loading from '../../components/Loading';

import './style.css';

class CadastroPaciente extends Component {

    constructor(props) {
        super(props)

        this.state = {
            id: (props.match.params.id !== undefined ? props.match.params.id : null),
            nome: "",
            nascimento: "",
            rg: "",
            escolaridade: "",
            escola: "",
            rede_escolar: "",
            raca: "",
            sexo: "",
            endereco: "",
            numero: "",
            bairro: "",
            cep: "",
            cidade: "",
            estado: "",
            observacao: "",
            successMessages: [],
            errorMessages: [],
            isLoading: false,
            token: localStorage.getItem("token")
        }

        this.cadastrar = this.cadastrar.bind(this);
        this.atualizar = this.atualizar.bind(this);
    }

    componentDidMount() {
        document.title = `Cadastro de Paciente`;
        window.scrollTo(0, 0);

        if (this.state.id !== null) {
            this.carregarDados();
        }
    }

    checkCEP(cepAtual) {
        this.setState({ cep: cepAtual });
        cepAtual = cepAtual.replaceAll("_", "");

        if (cepAtual.length >= 9) {
            //remove - e recupera dados de cep
            cep(cepAtual.replace("-", ""))
                .then((response) => {
                    console.log(response);
                    this.setState({
                        cidade: response.city,
                        estado: response.state,
                    })

                    if (response.neighborhood !== undefined && response.neighborhood !== "") {
                        this.setState({
                            bairro: response.neighborhood
                        });
                    }

                    if (response.street !== undefined && response.street !== "") {
                        this.setState({
                            endereco: response.street
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {

                });
        }
    }

    cadastrar(e) {
        e.preventDefault();
        window.scrollTo(0, 0);

        //ativa loading
        this.setState({
            isLoading: true,
            successMessages: [],
            errorMessages: []
        });

        api.post("/cadastra-paciente", this.state)
            .then((response) => {
                //esconde loading
                this.setState({
                    isLoading: false
                });

                if (response.data.status === "success") {
                    //mostra mensagem
                    this.setState({
                        successMessages: response.data.messages,
                        id: response.data.id
                    });
                }
                else {
                    this.setState({
                        errorMessages: response.data.messages
                    });
                }
            })
            .catch((err) => {
                //esconde loading
                this.setState({
                    isLoading: false,
                    errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
                });
            });
    }

    atualizar(e) {
        e.preventDefault();
        window.scrollTo(0, 0);

        if (this.state.id === null) {
            this.setState({
                errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
            });

            return;
        }

        //ativa loading
        this.setState({
            isLoading: true,
            successMessages: [],
            errorMessages: []
        });

        api.put("/atualiza-paciente/" + this.state.id, this.state)
            .then((response) => {
                //esconde loading
                this.setState({
                    isLoading: false
                });

                if (response.data.status === "success") {
                    //mostra mensagem
                    this.setState({
                        successMessages: response.data.messages
                    });
                }
                else {
                    this.setState({
                        errorMessages: response.data.messages
                    });
                }
            })
            .catch((err) => {
                //esconde loading
                this.setState({
                    isLoading: false,
                    errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
                });
            });
    }

    carregarDados() {
        //ativa loading
        this.setState({
            isLoading: true,
            successMessages: [],
            errorMessages: []
        });

        api.get("/get-paciente/" + this.state.id, { params: { token: localStorage.getItem("token") } })
            .then((response) => {
                //esconde loading
                this.setState({
                    isLoading: false
                });

                if (response.data.status === "success") {
                    //mostra mensagem
                    this.setState(response.data.dados);
                    this.setState(response.data.endereco);
                }
                else {
                    this.setState({
                        id: null,
                        errorMessages: response.data.messages
                    });
                }
            })
            .catch((err) => {
                //esconde loading
                this.setState({
                    isLoading: false,
                    id: null,
                    errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
                });
            });
    }

    render() {
        var escolaridade_array = [
            ["Berçário", "Berçário"],
            ["N1 Educação Infantil", "N1 Educação Infantil"],
            ["N2 Educação Infantil", "N2 Educação Infantil"],
            ["N3 Educação Infantil", "N3 Educação Infantil"],
            ["1º ano Ensino Fundamental", "1º ano Ensino Fundamental"],
            ["2º ano Ensino Fundamental", "2º ano Ensino Fundamental"],
            ["3º ano Ensino Fundamental", "3º ano Ensino Fundamental"],
            ["4º ano Ensino Fundamental", "4º ano Ensino Fundamental"],
            ["5º ano Ensino Fundamental", "5º ano Ensino Fundamental"],
            ["6º ano Ensino Fundamental", "6º ano Ensino Fundamental"],
            ["7º ano Ensino Fundamental", "7º ano Ensino Fundamental"],
            ["8º ano Ensino Fundamental", "8º ano Ensino Fundamental"],
            ["9º ano Ensino Fundamental", "9º ano Ensino Fundamental"],
            ["1º ano Ensino Médio", "1º ano Ensino Médio"],
            ["2º ano Ensino Médio", "2º ano Ensino Médio"],
            ["3º ano Ensino Médio", "3º ano Ensino Médio"],
            ["Superior incompleto", "Superior incompleto"],
            ["Superior completo", "Superior completo"],
            ["Pós-graduação", "Pós-graduação"],
            ["Outros", "Outros"]
        ];

        return (
            <div>
                <Header />

                <TopPage title={this.state.id === null ? "NOVO PACIENTE" : "ATUALIZAR PACIENTE"} back={this.state.id === null ? `${process.env.PUBLIC_URL}/pesquisador-dashboard` : `${process.env.PUBLIC_URL}/perfil-paciente/${this.state.id}`} />

                <form id="cadastro-paciente" method="POST" onSubmit={(e) => { this.state.id !== null ? this.atualizar(e) : this.cadastrar(e) }}>
                    {this.state.successMessages.length > 0 ? <Alert tipo="success" messages={this.state.successMessages} /> : null}
                    {this.state.errorMessages.length > 0 ? <Alert tipo="error" messages={this.state.errorMessages} /> : null}

                    <Input name="nome" id="nome" placeholder="Nome Completo" value={this.state.nome} onChange={(e) => { this.setState({ nome: e.target.value }) }} />
                    <Input name="nascimento" id="nascimento" placeholder="Data de Nascimento" mask="99/99/9999" value={this.state.nascimento} onChange={(e) => { this.setState({ nascimento: e.target.value }) }} />
                    <Input name="rg" id="rg" placeholder="RG" mask="99.999.999-*" value={this.state.rg} onChange={(e) => { this.setState({ rg: e.target.value }) }} />
                    {/* <Select name="raca" id="raca" placeholder="Raça" options={[["preta", "Preta"], ["parda", "Parda"], ["indígena", "Indígena"], ["amarela", "Amarela"], ["branca", "Branca"]]} value={this.state.raca} onChange={(e) => { this.setState({ raca: e.target.value }) }} /> */}
                    <Select name="genero" id="genero" placeholder="Sexo" options={[["F", "Feminino"], ["M", "Masculino"]]} value={this.state.sexo} onChange={(e) => { this.setState({ sexo: e.target.value }) }} />

                    <Input label="Endereço do paciente" name="cep" value={this.state.cep} id="cep" placeholder="CEP" mask="99999-999" onChange={(e) => { this.checkCEP(e.target.value); }} />
                    <Input name="endereco" value={this.state.endereco} id="endereco" placeholder="Endereço" onChange={(e) => { this.setState({ endereco: e.target.value }) }} />
                    <Input name="numero" value={this.state.numero} id="numero" placeholder="Número" onChange={(e) => { this.setState({ numero: e.target.value }) }} />
                    <Input name="bairro" value={this.state.bairro} id="bairro" placeholder="Bairro" onChange={(e) => { this.setState({ bairro: e.target.value }) }} />
                    <Input name="cidade" value={this.state.cidade} id="cidade" placeholder="Cidade" onChange={(e) => { this.setState({ cidade: e.target.value }) }} />
                    <Input name="estado" value={this.state.estado} id="estado" mask="aa" placeholder="Estado" onChange={(e) => { this.setState({ estado: e.target.value }) }} />

                    <Input label="Dados sobre escola" name="escola" id="escola" placeholder="Nome da escola" value={this.state.escola} onChange={(e) => { this.setState({ escola: e.target.value }) }} />
                    <Select name="escolaridade" id="escolaridade" placeholder="Escolaridade do paciente" options={escolaridade_array} value={this.state.escolaridade} onChange={(e) => { this.setState({ escolaridade: e.target.value }) }} />
                    <Select name="rede_escolar" id="rede_escolar" placeholder="Rede Escolar" options={[["Pública", "Pública"], ["Particular", "Particular"], ["Outros", "Outros"]]} value={this.state.rede_escolar} onChange={(e) => { this.setState({ rede_escolar: e.target.value }) }} />

                    <Textarea rows="6" label="Observação sobre o paciente" name="observacao" id="observacao" placeholder="Observação" value={this.state.observacao} onChange={(e) => { this.setState({ observacao: e.target.value }) }} />

                    <Button id="enviar" className="full" onClick={(e) => { this.state.id !== null ? this.atualizar(e) : this.cadastrar(e) }}>{this.state.id === null ? "Cadastrar" : "Atualizar"}</Button>
                    <Link to={this.state.id === null ? `${process.env.PUBLIC_URL}/pesquisador-dashboard` : `${process.env.PUBLIC_URL}/perfil-paciente/${this.state.id}`} className="cancelar">cancelar</Link>
                </form>

                {this.state.isLoading ? <Loading /> : null}

                <Footer />
            </div>
        );
    }

}

export default withRouter(CadastroPaciente);