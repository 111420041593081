import React from 'react';
import { Link } from 'react-router-dom';

import './style.css';

class Button extends React.Component {

    render() {
        return (
            <div className={`button ` + this.props.className}>
                {(this.props.link === undefined)?<button id={this.props.id} className="btn" type={this.props.type} onClick={this.props.onClick}>{this.props.children}</button>:<Link to={this.props.link} id={this.props.id} className="btn" onClick={this.props.onClick}>{this.props.children}</Link>}
                
            </div>
        );
    }
}

export default Button;