import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import Input from "../../components/Input";
import Footer from "../../components/Footer";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import api from "../../services/api";
import Alert from "../../components/Alert";
import CheckAuth from "../../services/auth";

import './style.css';
import ModalRemover from "../../components/ModalRemover";

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            authenticated: false,
            login: "",
            senha: "",
            isLoading: false,
            error: null,
            dashboardAdmin: false,
            dashboardPesquisador: false,
            modal: null
        }

        this.logar = this.logar.bind(this);
    }

    componentDidMount() {
        document.title = `Login`;
        window.scrollTo(0, 0);

        if (this.props.error !== undefined) {
            this.setState({ error: [this.props.error] });
        }

        //verifica se existe token
        if (localStorage.getItem("token") !== null) {
            this.checkLogin();
        }

        if (this.iOS()) {
            this.setState({
                modal: <ModalRemover onClick={() => (this.setState({ modal: null }))} onlymessage={true} message="Para que o Stroop funcione corretamente no iOS é preciso ativar o MediaRecorder. Para isso acesse: Ajustes > Safari > Avançado > Experimental Features e ative o item 'MediaRecorder'." />
            });
        }
    }

    checkLogin() {
        this.setState({ isLoading: true });

        CheckAuth()
            .then((response) => {
                if (!response) {
                    //remove dados local
                    localStorage.clear();
                }

                this.setState({ isLoading: false, authenticated: response });
            })
            .catch((err) => {
                this.setState({ isLoading: false, authenticated: false });
            });
    }

    logar(e) {
        e.preventDefault();

        //ativa loading
        this.setState({
            isLoading: true,
            error: null
        });

        api.post("/login",
            { login: this.state.login, senha: this.state.senha })
            .then((response) => {
                //desativa loading
                this.setState({
                    isLoading: false
                });

                if (response.data.status === "success") {
                    //recupera e salva dados do retorno
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem("tipo", response.data.tipo);
                    localStorage.setItem("dados", response.data.dados);

                    //se admin redireciona para tela de listagem de pesquisadores
                    if (response.data.tipo === 1) {
                        this.setState({ dashboardAdmin: true });
                    }
                    //se não, redireciona para tela de listagem de pacientes
                    else {
                        localStorage.setItem("id_pesquisador", response.data.dados.id_pesquisador);
                        this.setState({ dashboardPesquisador: true });
                    }
                }
                else {
                    //exibe erro com mensagem do retorno
                    this.setState({
                        error: response.data.messages
                    });
                }

            })
            .catch((err) => {
                //desativa loading e exibe erro
                this.setState({
                    isLoading: false,
                    error: ["Ocorreu um erro ao tentar efetuar o login. Tente novamente!"]
                });
            });
    }

    iOS() {
        var isIOS = [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document);

        var ua = window.navigator.userAgent;
        var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        var webkit = !!ua.match(/WebKit/i);
        var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

        return (isIOS && iOSSafari ? true : false);
    }

    render() {
        if (this.state.dashboardAdmin) {
            return <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />;
        }

        if (this.state.dashboardPesquisador) {
            return <Redirect to={`${process.env.PUBLIC_URL}/pesquisador-dashboard`} />;
        }

        return (
            <div id="login">
                <div className="border-top"></div>
                <form className="login-form" onSubmit={this.logar}>
                    <div className="logo">
                        <div className="logo-text">Stroop</div>
                        <div className="logo-version">v1.0.0</div>
                    </div>

                    {this.state.error !== null ? <Alert messages={this.state.error} tipo="error" /> : null}

                    <Input name="usuario" id="usuario" type="text" placeholder="Usuário" onChange={(e) => { this.setState({ login: e.target.value }) }} />
                    <Input name="senha" id="senha" type="password" placeholder="Senha" onChange={(e) => { this.setState({ senha: e.target.value }) }} />
                    <Button name="logar" id="logar" className="full" onClick={this.logar}>Login</Button>

                    <Link to={`${process.env.PUBLIC_URL}/esqueceu-senha`} className="esqueceu-senha">esqueceu sua senha?</Link>
                </form>

                {this.state.isLoading ? <Loading /> : null}

                {this.state.authenticated && (localStorage.getItem("tipo") === 1 || localStorage.getItem("tipo") === "1") ? <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} /> : null}
                {this.state.authenticated && (localStorage.getItem("tipo") === 2 || localStorage.getItem("tipo") === "2") ? <Redirect to={`${process.env.PUBLIC_URL}/pesquisador-dashboard`} /> : null}

                {this.state.modal}

                <Footer />
            </div>
        );
    }
}

export default Login;