import React, { Component } from 'react';
import { Link, withRouter  } from 'react-router-dom';
import api from '../../services/api';
import cep from 'cep-promise';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TopPage from '../../components/TopPage';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Alert from '../../components/Alert';
import Loading from '../../components/Loading';
import Select from '../../components/Select';

import './style.css';



class CadastroPesquisador extends Component {

    constructor(props) {
        super(props)

        this.state = {
            id: (props.match.params.id !== undefined ? props.match.params.id : null),
            nome: "",
            nascimento: "",
            documento: "",
            endereco: "",
            numero: "",
            bairro: "",
            cep: "",
            cidade: "",
            estado: "",
            telefone: "",
            email: "",
            password: "",
            confirma_senha: "",
            status: 1,
            successMessages: [],
            errorMessages: [],
            isLoading: false,
            token: localStorage.getItem("token")
        }

        this.cadastrar = this.cadastrar.bind(this);
        this.atualizar = this.atualizar.bind(this);
    }

    componentDidMount() {
        document.title = `Cadastro de Pesquisador`;
        window.scrollTo(0, 0);

        if (this.state.id !== null) {
            this.carregarDados();
        }
    }

    checkCEP(cepAtual) {
        this.setState({ cep: cepAtual });
        cepAtual = cepAtual.replaceAll("_", "");

        if (cepAtual.length >= 9) {
            //remove - e recupera dados de cep
            cep(cepAtual.replace("-", ""))
                .then((response) => {
                    console.log(response);
                    this.setState({
                        cidade: response.city,
                        estado: response.state,
                    })

                    if (response.neighborhood !== undefined && response.neighborhood !== "") {
                        this.setState({
                            bairro: response.neighborhood
                        });
                    }

                    if (response.street !== undefined && response.street !== "") {
                        this.setState({
                            endereco: response.street
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {

                });
        }
    }

    cadastrar(e) {
        e.preventDefault();
        window.scrollTo(0, 0);

        //ativa loading
        this.setState({
            isLoading: true,
            successMessages: [],
            errorMessages: []
        });

        api.post("/cadastra-pesquisador", this.state)
            .then((response) => {
                //esconde loading
                this.setState({
                    isLoading: false
                });

                if (response.data.status === "success") {
                    //mostra mensagem
                    this.setState({
                        successMessages: response.data.messages,
                        id: response.data.id
                    });
                }
                else {
                    this.setState({
                        errorMessages: response.data.messages
                    });
                }
            })
            .catch((err) => {
                //esconde loading
                this.setState({
                    isLoading: false,
                    errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
                });
            });
    }

    atualizar(e) {
        e.preventDefault();
        window.scrollTo(0, 0);

        if (this.state.id === null) {
            this.setState({
                errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
            });

            return;
        }

        //ativa loading
        this.setState({
            isLoading: true,
            successMessages: [],
            errorMessages: []
        });

        api.put("/atualiza-pesquisador/" + this.state.id, this.state)
            .then((response) => {
                //esconde loading
                this.setState({
                    isLoading: false
                });

                if (response.data.status === "success") {
                    //mostra mensagem
                    this.setState({
                        successMessages: response.data.messages
                    });
                }
                else {
                    this.setState({
                        errorMessages: response.data.messages
                    });
                }
            })
            .catch((err) => {
                //esconde loading
                this.setState({
                    isLoading: false,
                    errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
                });
            });
    }

    carregarDados () {
        //ativa loading
        this.setState({
            isLoading: true,
            successMessages: [],
            errorMessages: []
        });

        api.get("/get-pesquisador/" + this.state.id, { params: { token: localStorage.getItem("token") }})
            .then((response) => {
                //esconde loading
                this.setState({
                    isLoading: false
                });

                if (response.data.status === "success") {
                    //mostra mensagem
                    this.setState(response.data.dados);
                    this.setState(response.data.endereco);
                }
                else {
                    this.setState({
                        id: null,
                        errorMessages: response.data.messages
                    });
                }
            })
            .catch((err) => {
                //esconde loading
                this.setState({
                    isLoading: false,
                    id: null,
                    errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
                });
            });
    }

    render() {
        return (
            <div>
                <Header />

                <TopPage title={ this.state.id === null ? "NOVO PESQUISADOR" : "ATUALIZAR PESQUISADOR" } back={`${process.env.PUBLIC_URL}/dashboard`} />

                <form id="cadastro-pesquisador" method="POST" onSubmit={(e) => { this.state.id !== null ? this.atualizar(e) : this.cadastrar(e) }}>
                    {this.state.successMessages.length > 0 ? <Alert tipo="success" messages={this.state.successMessages} /> : null}
                    {this.state.errorMessages.length > 0 ? <Alert tipo="error" messages={this.state.errorMessages} /> : null}


                    <Input name="nome" value={this.state.nome} id="nome" placeholder="Nome Completo" onChange={(e) => { this.setState({ nome: e.target.value }) }} />
                    <Input name="nascimento" value={this.state.nascimento} id="nascimento" placeholder="Data de Nascimento" mask="99/99/9999" onChange={(e) => { this.setState({ nascimento: e.target.value }) }} />
                    <Input name="cpf" value={this.state.documento} id="cpf" placeholder="CPF" mask="999.999.999-99" onChange={(e) => { this.setState({ documento: e.target.value }) }} />

                    <Input label="Endereço do pesquisador" name="cep" value={this.state.cep} id="cep" placeholder="CEP" mask="99999-999" onChange={(e) => { this.checkCEP(e.target.value); }} />
                    <Input name="endereco" value={this.state.endereco} id="endereco" placeholder="Endereço" onChange={(e) => { this.setState({ endereco: e.target.value }) }} />
                    <Input name="numero" value={this.state.numero} id="numero" placeholder="Número" onChange={(e) => { this.setState({ numero: e.target.value }) }} />
                    <Input name="bairro" value={this.state.bairro} id="bairro" placeholder="Bairro" onChange={(e) => { this.setState({ bairro: e.target.value }) }} />
                    <Input name="cidade" value={this.state.cidade} id="cidade" placeholder="Cidade" onChange={(e) => { this.setState({ cidade: e.target.value }) }} />
                    <Input name="estado" value={this.state.estado} id="estado" mask="aa" placeholder="Estado" onChange={(e) => { this.setState({ estado: e.target.value }) }} />
                    <Input name="telefone" value={this.state.telefone} id="telefone" placeholder="Telefone" mask="(99) 99999-9999" onChange={(e) => { this.setState({ telefone: e.target.value }) }} />

                    <Input label="Credenciais de acesso" name="email" value={this.state.email} type="email" id="email" placeholder="Email" onChange={(e) => { this.setState({ email: e.target.value }) }} />
                    <Input name="senha" value={this.state.password} type="password" id="senha" placeholder="Senha" onChange={(e) => { this.setState({ password: e.target.value }) }} />
                    <Input name="repetir_senha" value={this.state.confirma_senha} type="password" id="repetir_senha" placeholder="Repetir Senha" onChange={(e) => { this.setState({ confirma_senha: e.target.value }) }} />

                    <Select name="status" value={this.state.status} placeholder="Status da conta" options={[["1", "Acesso liberado"], ["0", "Bloqueado"]]} id="status" onChange={(e) => { this.setState({ status: e.target.value }) }} />

                    <Button id="enviar" className="full" onClick={(e) => { this.state.id !== null ? this.atualizar(e) : this.cadastrar(e) }}>{ this.state.id === null ? "Cadastrar" : "Atualizar" }</Button>
                    <Link to={`${process.env.PUBLIC_URL}/dashboard`} className="cancelar">cancelar</Link>
                </form>

                {this.state.isLoading ? <Loading /> : null}

                <Footer />
            </div>
        );
    }

}

export default withRouter(CadastroPesquisador);