import React from 'react';
import { Link } from 'react-router-dom';
import ModalRemover from '../ModalRemover';
import api from '../../services/api';
import Loading from '../../components/Loading';
import Alert from '../Alert';

import './style.css';


class ListPesquisadores extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pesquisadores: props.pesquisadores,
            remover: null,
            isLoading: false,
            list: [],
            successMessages: [],
            errorMessages: []
        }

        this.removerPesquisador = this.removerPesquisador.bind(this);
        this.getListPesquisadores = this.getListPesquisadores.bind(this);
    }

    componentWillMount() {
        //recupera cadastro de pesquisadores
        this.getListPesquisadores();
    }

    removerPesquisador(id) {
        this.setState({
            successMessages: [],
            errorMessages: []
        });

        this.setState({
            remover:
                <ModalRemover remover={() => { this.deletePesquisador(id) }} onClick={() => { this.setState({ remover: null }) }} />
        });
    }

    getListPesquisadores() {
        //ativa loading
        this.setState({
            remover: null,
            isLoading: true,
            successMessages: [],
            errorMessages: []
        });

        api.get(this.state.pesquisadores ? "/list-pesquisadores" : "/list-admin", { params: { token: localStorage.getItem("token") } })
            .then((response) => {
                //esconde loading
                this.setState({
                    isLoading: false
                });

                if (response.data.status === "success") {
                    //lista dados
                    this.setState({
                        list: response.data.dados
                    })
                }
            })
            .catch((err) => {
                //esconde loading
                this.setState({
                    isLoading: false
                });
            });
    }

    deletePesquisador(id) {
        //ativa loading
        this.setState({
            remover: null,
            isLoading: true,
            successMessages: [],
            errorMessages: []
        });

        console.log(id);

        api.delete(this.state.pesquisadores ? "/remover-pesquisador/" + id : "/remover-admin/" + id, { params: { token: localStorage.getItem("token") } })
            .then((response) => {
                //esconde loading
                this.setState({
                    isLoading: false
                });

                if (response.data.status === "success") {
                    //recarrega pesquisadores
                    this.getListPesquisadores();

                    //mostra mensagem
                    this.setState({
                        successMessages: response.data.messages
                    });
                }
                else {
                    this.setState({
                        errorMessages: response.data.messages
                    });
                }
            })
            .catch((err) => {
                //esconde loading
                this.setState({
                    isLoading: false,
                    errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
                });
            });
    }

    render() {
        return (
            <content id="list-pesquisadores">
                <div className="container">

                    {this.state.successMessages.length > 0 ? <Alert tipo="success" messages={this.state.successMessages} /> : null}
                    {this.state.errorMessages.length > 0 ? <Alert tipo="error" messages={this.state.errorMessages} /> : null}

                    <div className="list">
                        <div className="list-hrow">
                            <div className="list-item">Nome do {this.state.pesquisadores ? "pesquisador" : "administrador"}</div>
                            <div className="list-item">E-mail</div>
                            <div className="list-item">{this.state.pesquisadores ? "Telefone" : ""}</div>
                            <div className="list-item">Ações</div>
                        </div>

                        {this.state.list.map((value, index) => {
                            return <div className="list-body" key={`item-` + index}>
                                <div className="list-item">{value.nome}</div>
                                <div className="list-item">{value.email}</div>
                                <div className="list-item">{this.state.pesquisadores ? value.telefone : ""}</div>

                                {this.state.pesquisadores
                                    ?
                                    <div className="list-item">
                                        <Link to={`${process.env.PUBLIC_URL}/editar-pesquisador/${value.id_pesquisador}`} className="btn-list btn-editar">Editar</Link>
                                        <div className="btn-list btn-remover" onClick={() => { this.removerPesquisador(value.id_pesquisador); }}>Remover</div>
                                    </div>
                                    :
                                    <div className="list-item">
                                        <Link to={`${process.env.PUBLIC_URL}/editar-administrador/${value.id_administrador}`} className="btn-list btn-editar">Editar</Link>
                                        <div className="btn-list btn-remover" onClick={() => { this.removerPesquisador(value.id_administrador); }}>Remover</div>
                                    </div>
                                }

                            </div>;
                        })}

                        {
                            this.state.list.length === 0 ? <div className="nenhum-registro">Nenhum {this.state.pesquisadores ? "pesquisador" : "administrador"} encontrado.</div> : ""
                        }

                    </div>
                </div>

                {this.state.remover}

                {this.state.isLoading ? <Loading /> : null}
            </content>
        );
    }
}

export default ListPesquisadores;