import api from './api';

export default async function CheckAuth () {
    var retorno = false;

    if (localStorage.getItem("token") === null) {
        retorno = false;
    }
    else {
        //valida token
        await api.get("/check-token/" + localStorage.getItem("token") + "/" + localStorage.getItem("tipo"))
        .then((response) => {
            if (response.data.status !== undefined) {
                if (response.data.status === "success") {
                    retorno = true;
                }
                else {
                    retorno = false;
                }
            }
            else {
                retorno = false;
            }
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    }

    return retorno;
}