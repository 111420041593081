import React from "react";
import "./style.css";

export default class Loading extends React.Component {

    render() {
        return (
            <div className="loading">
                <div className="loader"></div>
            </div>
        );
    }

}