import React, { useState } from 'react';
import './style.css';

export default function CopyInput(props) {
    const value = props.value;

    const [btnText, setBtnText] = useState("Copiar");
    const [classButton, setClassButton] = useState("link-copiar");

    function copy (e) {
        setBtnText("Copiado");
        setClassButton("link-copiar copiado");

        //copia value para transferencia para transferencia
        navigator.clipboard.writeText(value);

        setTimeout(() => {
            setBtnText("Copiar");
            setClassButton("link-copiar");
        }, 1000);

        e.preventDefault();
    }

    return (
        <div className="link-group">
            <input name="link" disabled value={props.value} />
            <button onClick={copy} className={classButton} type="button">{btnText}</button>
        </div>
    );
}