import React, { Component } from "react";

import { Link } from "react-router-dom";
import Input from "../../components/Input";
import Footer from "../../components/Footer";
import Button from "../../components/Button";

import './style.css';
import Alert from "../../components/Alert";
import Loading from "../../components/Loading";
import api from "../../services/api";

class EsqueceuSenha extends Component {

    constructor(props) {
        super(props)

        this.state = {
            email: "",
            successMessages: [],
            errorMessages: [],
            isLoading: false,
        }

        this.enviar = this.enviar.bind(this);
    }

    componentDidMount() {
        document.title = `Esqueci a senha`;
        window.scrollTo(0, 0);
    }

    enviar(e) {
        e.preventDefault();

        //ativa loading
        this.setState({
            isLoading: true,
            successMessages: [],
            errorMessages: []
        });

        api.post("/esqueceu-senha", {email: this.state.email})
            .then((response) => {
                if (response.data.status === "success") {
                    //mostra mensagem
                    this.setState({
                        email: "",
                        successMessages: response.data.messages
                    });
                }
                else {
                    this.setState({
                        errorMessages: response.data.messages
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
                });
            })
            .finally(() => {
                //esconde loading
                this.setState({
                    isLoading: false
                });
            })
    }

    render() {
        return (
            <div id="login">
                <div className="border-top"></div>
                <form className="login-form" onSubmit={(e) => { this.enviar(e) }}>
                    <div className="logo">
                        <div className="logo-text">Stroop</div>
                        <div className="logo-version">v1.0.0</div>
                    </div>

                    {this.state.successMessages.length > 0 ? <Alert tipo="success" messages={this.state.successMessages} /> : null}
                    {this.state.errorMessages.length > 0 ? <Alert tipo="error" messages={this.state.errorMessages} /> : null}

                    <div className="text-destaque">Digite seu email para recuperar sua senha:</div>
                    <Input value={this.state.email} name="email" id="email" type="text" placeholder="Email" onChange={(e) => { this.setState({ email: e.target.value }) }} />
                    <Button name="logar" id="logar" className="full" onClick={(e) => { this.enviar(e) }}>Recuperar</Button>

                    <Link to={`${process.env.PUBLIC_URL}/`} className="esqueceu-senha">cancelar</Link>
                </form>

                {this.state.isLoading ? <Loading /> : null}

                <Footer />
            </div>
        );
    }
}

export default EsqueceuSenha;