import React, { Component } from 'react';
import { FaUserAlt } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TopPage from '../../components/TopPage';
import Estimulos from '../../components/Estimulos';
import ModalSound from '../../components/ModalSound';
import contentTest from '../../services/var';
import api from '../../services/api';

import './style.css';
import Loading from '../../components/Loading';

class ModuloPaciente extends Component {

    dados = contentTest;
    content = [];
    refs = [];

    constructor(props) {
        super(props);

        const { module_number } = this.props.match.params;

        this.state = {
            id: (props.match.params.id !== undefined ? props.match.params.id : null),
            modulo: parseInt(module_number),
            modal: null,
            isLoading: false,
            id_paciente: null,
            nome_paciente: null,
            escolaridade: null,
            nascimento: null,
            idade: "",
            data_teste: "-",
            respostas: []
        };

        this.edit = this.edit.bind(this);
        this.play = this.play.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        document.title = `Módulo`;
        window.scrollTo(0, 0);

        this.carregaDados();
    }

    carregaDados() {
        this.setState({ isLoading: true });

        api.get("/teste/" + this.state.id + "/modulo/" + this.state.modulo, { params: { token: localStorage.getItem("token") } })
            .then((response) => {
                if (response.data.status === "success") {
                    this.setState({
                        module_complete: response.data.modulos,
                        id_paciente: response.data.paciente.id_paciente,
                        nascimento: response.data.paciente.nascimento,
                        escolaridade: response.data.paciente.escolaridade,
                        nome_paciente: response.data.paciente.nome,
                        idade: response.data.idade,
                        data_teste: response.data.teste.created_at,
                        respostas: response.data.respostas
                    });
                }
            })
            .catch((err) => {

            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    edit(id) {
        this.setState({
            modal: <ModalSound id={id} close={this.closeModal} />
        });
    }

    closeModal() {
        this.setState({
            modal: null
        });

        //atualiza dados
        this.carregaDados();
    }

    play(id) {
        this.content.forEach((child, i) => {
            if (child.type === Estimulos) {
                console.log(i + " - " + id);
                if (i === id) {
                    //reproduz botao
                    this[`estimulo${i}`].play();
                    console.log("TOCA");
                }
                else {
                    //para audio
                    this[`estimulo${i}`].stop();
                    console.log("PARA");
                }
            }
        });
    }

    render() {
        this.content = [];
        //for(var i = 0; i < this.state.respostas.length; i++) {
        this.state.respostas.map((value, index) => {
            if (this.state.modulo !== 3) {
                this.content.push(<Estimulos onRef={ref => (this[`estimulo${index}`] = ref)} key={index} nome={`${(index + 1)}. ${this.dados[this.state.modulo - 1][0][index]}`} score={value.score} tr={value.tempo_reacao} edit={() => { this.edit(value.id_resposta); }} audio={value.audio} play={() => { this.play(index); }} status={value.status} />);
            }
            else {
                this.content.push(<Estimulos onRef={ref => (this[`estimulo${index}`] = ref)} key={index} nome={`${(index + 1)}. ${this.dados[this.state.modulo - 1][1][index]}`} cor={this.dados[this.state.modulo - 1][0][index]} score={value.score} tr={value.tempo_reacao} edit={() => { this.edit(value.id_resposta); }} audio={value.audio} play={() => { this.play(index); }} status={value.status} />);
            }

            return value;
        });

        return (
            <div>
                <Header />

                <div className="content">
                    <TopPage title={this.state.nome_paciente}
                        back={`${process.env.PUBLIC_URL}/teste/${this.state.id}/modulos`}
                        small={`Data de nascimento: ${this.state.nascimento} - ${this.state.idade}`}
                        small2={`Escolaridade: ${this.state.escolaridade}`}
                        small3={`Data do teste: ${this.state.data_teste}`}
                        icon={true}>
                        <FaUserAlt />
                    </TopPage>

                    <div className="container">
                        <div className="title-modulo">Módulo {this.state.modulo}</div>

                        <div className={`header-estimulos${this.state.modulo === 3 ? " header-estimulos-3" : ""}`}>
                            <div>Estímulo</div>
                            {this.state.modulo === 3 ? <div>Cor</div> : null}
                            <div>Score</div>
                            <div>Tempo de Reação (TR)</div>
                            <div>Status</div>
                            <div></div>
                        </div>

                        {this.content.map((value, index) => {
                            return value;
                        })}
                    </div>
                </div>

                <Footer />

                { this.state.isLoading ? <Loading /> : null}

                { this.state.modal}
            </div >
        );
    }

}

export default withRouter(ModuloPaciente);