import React from 'react';
import { Link } from 'react-router-dom';
import { FaRegTrashAlt, FaUserAlt, FaRegEdit } from 'react-icons/fa';
import ModalRemover from '../ModalRemover';
import Alert from '../Alert';
import api from '../../services/api';
import Loading from '../Loading';

import './style.css';

class ListPacientes extends React.Component {

    lista = [];

    constructor(props) {
        super(props);

        this.state = {
            lista: this.lista,
            aux_lista: this.lista,
            nome: "",
            remover: null,
            isLoading: false,
            successMessages: [],
            errorMessages: []
        }

        this.busca = this.busca.bind(this);
    }

    componentWillMount() {
        //recupera cadastro de pacientes
        this.getListPaciente();
    }

    componentWillUpdate(props, state) {
        if (props.nome !== this.state.nome) {
            this.setState({
                nome: props.nome
            }, () => this.busca());
        }
    }

    getListPaciente() {
        this.setState({
            remover: null,
            isLoading: true
        });

        api.get("/list-pacientes", { params: { token: localStorage.getItem("token") } })
            .then((response) => {
                this.setState({ isLoading: false, lista: response.data.dados, aux_lista: response.data.dados });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
            });
    }

    deletePaciente(id) {
        //ativa loading
        this.setState({
            remover: null,
            isLoading: true,
            successMessages: [],
            errorMessages: []
        });

        api.delete("/remover-paciente/" + id, { params: { token: localStorage.getItem("token") } })
            .then((response) => {
                //esconde loading
                this.setState({
                    isLoading: false
                });

                if (response.data.status === "success") {
                    //mostra mensagem
                    this.setState({
                        successMessages: response.data.messages
                    });

                    //recarrega pesquisadores
                    this.getListPaciente();
                }
                else {
                    this.setState({
                        errorMessages: response.data.messages
                    });
                }
            })
            .catch((err) => {
                //esconde loading
                this.setState({
                    isLoading: false,
                    errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
                });
            });
    }

    busca() {
        var nova_busca = [];

        this.state.aux_lista.forEach((value, index) => {
            console.log(value.nome);
            if (value.nome.toLowerCase().includes(this.state.nome.toLowerCase())) {
                nova_busca.push(value);
            }
        });

        //salva nova lista
        this.setState({
            lista: nova_busca
        });
    }

    removerPaciente(id) {
        this.setState({
            successMessages: [],
            errorMessages: []
        });

        this.setState({
            remover:
                <ModalRemover remover={() => { this.deletePaciente(id) }} onClick={() => { this.setState({ remover: null }) }} />
        });
    }

    render() {
        var loop = [];

        for (var i = 0; i < this.state.lista.length; i++) {
            const id = this.state.lista[i].id_paciente;
            loop.push(
                <div className="paciente-body" key={this.state.lista[i].id_paciente} >
                    <div className={`icon-paciente${this.state.lista[i].sexo === "F" ? " feminino" : ""}`}><FaUserAlt /></div>
                    <Link to={`${process.env.PUBLIC_URL}/perfil-paciente/${this.state.lista[i].id_paciente}`} className="nome-paciente">
                        {this.state.lista[i].nome}
                        <i><FaRegEdit /></i>
                        <div className="dados-teste">Nascimento: {this.state.lista[i].nascimento}</div>
                    </Link>
                    <button className="btn-remover-paciente" onClick={() => { this.removerPaciente(id) }}><FaRegTrashAlt /></button>
                </div>
            );
        }

        return (
            <content id="list-pacientes" >
                <div className="container">

                    <div className="list">
                        {this.state.successMessages.length > 0 ? <Alert tipo="success" messages={this.state.successMessages} /> : null}
                        {this.state.errorMessages.length > 0 ? <Alert tipo="error" messages={this.state.errorMessages} /> : null}

                        {loop.map((value) => {
                            return value;
                        })}

                        {
                            loop.length === 0 ? <div className="nenhum-registro">Nenhum paciente encontrado.</div> : ""
                        }

                    </div>
                </div>

                {this.state.remover}

                {this.state.isLoading ? <Loading /> : null}
            </content>
        );
    }
}

export default ListPacientes;