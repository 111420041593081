import React, { useState, useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import Input from "../../components/Input";
import Footer from "../../components/Footer";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import api from "../../services/api";

import './testeRemoto.css';
import ModalRemover from '../../components/ModalRemover';

export default withRouter(
    function TesteRemoto(props) {
        const remote_code = props.match.params.remote_code;

        const [page, setPage] = useState(null);
        const [isLoading, setIsLoading] = useState(false);
        const [error, setError] = useState(null);
        const [start, setStart] = useState(false);
        const [modal, setModal] = useState(null);
        // const [continuar, setContinuar] = useState(false);

        const [pass, setPass] = useState("");

        /**
         * Valida o teste remoto
         */
        function validaTesteRemoto() {
            setError(null);
            setIsLoading(true);

            api.get(`/teste-remoto/${remote_code}/validar`)
                .then((response) => {
                    //desativa loading
                    setIsLoading(false);

                    if (response.data.status === "success") {
                        console.log("valido!");

                        //if (response.data.is_valid && !response.data.was_answered)
                        changePage(
                            response.data.is_valid,
                            response.data.was_answered,
                            response.data.need_pass
                        );

                    }
                    else {
                        //exibe erro com mensagem do retorno
                        setError(
                            <ModalRemover onlymessage={true} message={response.data.messages[0]} onClick={() => { setError(null) }} />
                        );
                    }

                })
                .catch((err) => {
                    //desativa loading
                    setIsLoading(false);
                    setError(
                        <ModalRemover onlymessage={true} message="Ocorreu um erro ao tentar validar o teste remoto." onClick={() => { setError(null) }} />
                    );
                });
        }

        /**
         * Valida senha caso for necessario o preenchimento de senha
         */
        function validaSenha(e) {
            e.preventDefault();

            setError(null);
            setIsLoading(true);

            api.post(`/teste-remoto/${remote_code}/validar-senha`, { remote_pass: pass })
                .then((response) => {
                    //desativa loading
                    setIsLoading(false);

                    if (response.data.status === "success") {
                        console.log("senha valida!");

                        setPageTutorial();
                    }
                    else {
                        //exibe erro com mensagem do retorno
                        setError(
                            <ModalRemover onlymessage={true} message={response.data.messages[0]} onClick={() => { setError(null) }} />
                        );
                    }

                })
                .catch((err) => {
                    //desativa loading
                    setIsLoading(false);
                    setError(
                        <ModalRemover onlymessage={true} message="Ocorreu um erro ao tentar validar a senha do teste remoto." onClick={() => { setError(null) }} />
                    );
                });
        }

        /**
         * Define qual pagina ira aparecer
         * @param {bool} is_valid se for um teste valido valido
         * @param {bool} was_answered se ja foi ou nao respondido
         * @param {bool} need_pass se precisa ou nao de 
         */
        function changePage(is_valid, was_answered, need_pass) {
            //se for valido e ja foi respondido
            if (is_valid && !was_answered) {
                if (need_pass) {
                    //mostra form para preencher senha
                    setPage("senha")
                }
                else {
                    setPageTutorial();
                }
            }
            else {
                //mostra mensagem de erro
                setError(
                    <ModalRemover onlymessage={true} message="Esse teste não existe ou já foi respondido." onClick={() => { setError(null) }} />
                );
            }
        }

        /**
         * Inicia teste remoto
         */
        function StartTest() {
            try {
                if (navigator.mediaDevices.getUserMedia !== null) {
                    var options = {
                        video: false,
                        audio: true
                    };
                    navigator.mediaDevices.getUserMedia(options)
                        .then((stream) => {
                            //para stream
                            stream.getTracks().forEach(x => x.stop());

                            //salva dados do teste
                            localStorage.setItem("remote_code", remote_code);
                            localStorage.setItem("remote_pass", pass);

                            //redireciona
                            setStart(true);
                        })
                        .catch((err) => {
                            setModal(
                                <ModalRemover onClick={() => (setModal(null))} onlymessage={true} message="É preciso dar permissão ao microfone para continuar" />
                            );
                        });
                }
                else {
                    setModal(
                        <ModalRemover onClick={() => (setModal(null))} onlymessage={true} message="Nenhum microfone encontrado ou seu navegador não tem permissão para acessa-lo." />
                    );
                }
            }
            catch (e) {
                setModal(
                    <ModalRemover onClick={() => (setModal(null))} onlymessage={true} message="Nenhum microfone encontrado ou seu navegador não tem permissão para acessa-lo." />
                );
            }
        }

        /**
         * Mostra o tutorial na tela
         */
        function setPageTutorial() {
            //mostra tutorial
            setPage(
                <div className="tutorial">
                    <h1 className="header_testeremoto">Stroop App</h1>
                    <h4>Autores:</h4>
                    <p>
                        - Alessandra G. Seabra;<br />
                        - Natália M. Dias;<br />
                        - Elizeu C. Macedo;
                    </p>

                    <h4>Instruções:</h4>
                    <p>
                        - Por favor, permita habilitação de seu microfone;<br />
                        - Responda ao teste em voz alta, pois sua resposta será gravada (responda como você fala normalmente);<br />
                        - O teste tem 3 etapas. Leia as instruções que aparecerão ao início de cada uma delas;<br />
                        - As telas de itens de cada etapa mudam automaticamente;<br />
                        - Uma vez iniciada, não há como pausar pela metade qualquer uma das etapas. O tempo de cada uma delas é de aproximadamente 2 minutos;<br />
                        - Ao final de cada etapa, aguarde o tempo de processamento do aplicativo. Após esse período, a próxima iniciará automaticamente;<br /><br />
                        Obrigado!
                    </p>

                    <Button name="continuar" id="continuar" className="full" onClick={StartTest}>Continuar</Button>
                </div>
            );
        }

        function iniciaMicrofone() {
            try {
                if (navigator.mediaDevices.getUserMedia !== null) {
                    var options = {
                        video: false,
                        audio: true
                    };
                    navigator.mediaDevices.getUserMedia(options)
                        .then((stream) => {
                            //para stream
                            stream.getTracks().forEach(x => x.stop());
                        })
                        .catch((err) => {
                            setModal(
                                <ModalRemover onClick={() => (setModal(null))} onlymessage={true} message="É preciso dar permissão ao microfone para continuar" />
                            );
                        });
                }
                else {
                    setModal(
                        <ModalRemover onClick={() => (setModal(null))} onlymessage={true} message="Nenhum microfone encontrado ou seu navegador não tem permissão para acessa-lo." />
                    );
                }
            }
            catch (e) {
                setModal(
                    <ModalRemover onClick={() => (setModal(null))} onlymessage={true} message="Nenhum microfone encontrado ou seu navegador não tem permissão para acessa-lo." />
                );
            }
        }

        function iOS() {
            var isIOS = [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
                // iPad on iOS 13 detection
                || (navigator.userAgent.includes("Mac") && "ontouchend" in document);

            var ua = window.navigator.userAgent;
            var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
            var webkit = !!ua.match(/WebKit/i);
            var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

            return (isIOS && iOSSafari ? true : false);
        }

        useEffect(() => {
            //valida teste
            validaTesteRemoto();

            //inicializa microfone
            iniciaMicrofone();

            //verifica se é ios
            if (iOS()) {
                setModal(
                    <ModalRemover onClick={() => (setModal(null))} onlymessage={true} message="Para que o Stroop funcione corretamente no iOS é preciso ativar o MediaRecorder. Para isso acesse: Ajustes > Safari > Avançado > Experimental Features e ative o item 'MediaRecorder'." />
                );
            }

            //seta title
            document.title = "Stroop - Teste Remoto";
        }, []);

        return (
            <div id="remoto">
                <header id="header">
                    <div className="top-bar">
                        <div className="logo">
                            <div className="logo-text">Stroop</div>
                            <div className="logo-version">v1.0.0</div>
                        </div>
                    </div>
                </header>
                <form className={`login-form` + (page !== "senha" ? " form-tutorial" : "")} onSubmit={validaSenha}>
                    {error !== null ? error : null}

                    {page === "senha" ?
                        <>
                            <h1 className="header_testeremoto">Teste remoto</h1>
                            <Input name="senha" id="senha" type="text" placeholder="Senha" onChange={(e) => { setPass(e.target.value) }} />
                            <Button name="logar" id="logar" className="full" onClick={validaSenha}>Entrar</Button>
                        </>
                        :
                        page
                    }
                </form>

                {isLoading && <Loading />}

                {modal !== null ? modal : null}
                {start ? <Redirect to={`${process.env.PUBLIC_URL}/teste-remoto/${remote_code}/iniciar/1`} /> : null}

                <Footer />
            </div>
        );
    }
);