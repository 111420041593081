import React, { Component } from 'react';
import { FaMicrophone } from 'react-icons/fa';
import Button from '../../components/Button';
import contentTest from '../../services/var';
import { ReactMic } from 'react-mic';
import { withRouter } from 'react-router-dom';
import api from '../../services/api';
import Loading from '../../components/Loading';
import ModalRemover from '../../components/ModalRemover';

import './style.css';

class TesteRemotoIniciar extends Component {

    time = 50;
    dados = contentTest;
    timer = null;
    timerCount = 0;
    audio = null;

    //automatico
    timeToChange = 5000;

    respostas = [];

    constructor(props) {
        super(props);

        const { module_number } = this.props.match.params;

        this.state = {
            modulo: parseInt(module_number),
            pergunta: 1,
            n_perguntas: 24,
            mostra_resposta: true,
            teste: 1,
            screen: 1,
            remote_code: localStorage.getItem("remote_code"),
            remote_pass: localStorage.getItem("remote_pass"),
            record: false,
            proximo_modulo: false,
            modal: null,
            isLoading: false,
            modulo_content: null,
            fez_teste: false
        };

        this.inicia = this.inicia.bind(this);
        this.progresso = this.progresso.bind(this);
        this.proxima = this.proxima.bind(this);
        this.finalizarModulo = this.finalizarModulo.bind(this);
        this.finalizarTreino = this.finalizarTreino.bind(this);
        this.iniciaTeste = this.iniciaTeste.bind(this);
        this.proximoTeste = this.proximoTeste.bind(this);
        this.onStop = this.onStop.bind(this);
        this.validaTeste = this.validaTeste.bind(this);
    }

    componentDidMount() {
        document.title = `Stroop - Teste remoto`;
        window.scrollTo(0, 0);

        this.timerCount = 0;

        //valida teste
        this.validaTeste();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.stopRecording();

        //limpa dados
        this.respostas = [];
    }

    startRecording = () => {
        this.setState({ record: true }, () => {
            this.timer = setInterval(() => {
                //this.setState({ timer: this.state.timer + 50 })
                this.timerCount += 50;
            }, 50);
        });
    }

    stopRecording = () => {
        this.setState({ record: false });
    }

    onData(recordedBlob) {
        // console.log('chunk of real-time data is: ', recordedBlob);
    }

    blobToBase64 = blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };

    onStop(recordedBlob) {
        this.blobToBase64(recordedBlob.blob).then(res => {
            this.audio = res;

            //finaliza modulo
            this.finalizarModulo();
        });
    }

    inicia() {
        try {
            if (navigator.mediaDevices.getUserMedia !== null) {
                var options = {
                    video: false,
                    audio: true
                };
                navigator.mediaDevices.getUserMedia(options)
                    .then((stream) => {
                        //para stream
                        stream.getTracks().forEach(x => x.stop());

                        //vai para a pergunta
                        this.startRecording();
                        this.proxima(0);
                    })
                    .catch((err) => {
                        this.setState({
                            modal: <ModalRemover onClick={() => (this.setState({ modal: null }))} onlymessage={true} message="É preciso dar permissão ao microfone para continuar" />
                        });
                    });
            }
            else {
                this.setState({
                    modal: <ModalRemover onClick={() => (this.setState({ modal: null }))} onlymessage={true} message="Nenhum microfone encontrado ou seu navegador não tem permissão para acessa-lo." />
                });
            }
        }
        catch (e) {
            this.setState({
                modal: <ModalRemover onClick={() => (this.setState({ modal: null }))} onlymessage={true} message="Nenhum microfone encontrado ou seu navegador não tem permissão para acessa-lo." />
            });
        }
    }

    progresso() {
        //registra dados da resposta
        if (this.state.pergunta > 0) {
            console.log("PROXIMA!");
            var content = {
                pergunta: this.state.pergunta,
                estimulo: this.dados[this.state.modulo - 1][0][this.state.pergunta - 1],
                inicio: (this.respostas[this.state.pergunta - 2] === undefined ? 0.00 : this.respostas[this.state.pergunta - 2].fim),
                fim: (this.timerCount / 1000)
            };

            if (this.respostas[this.state.pergunta - 1] === undefined) {
                this.respostas.push(content);
            }
            else {
                this.respostas[this.state.pergunta - 1] = content;
            }
        }

        if (this.state.pergunta >= this.state.n_perguntas) {
            //finaliza teste
            clearInterval(this.timer);
            this.stopRecording();
        }
        else {
            this.proxima(1);
        }
    }

    proxima(add = 1) {
        this.setState({
            screen: 2,
            pergunta: (this.state.pergunta + add),
            mostra_resposta: true,
        }, () => {
            this.setaPalavra();

            if (this.state.modulo > 1) {
                //timer para esconder resposta
                setTimeout(() => this.setState({ mostra_resposta: false }), this.time);
            }
        });
    }

    setaPalavra() {
        switch (this.state.modulo) {
            default:
            case 1:
                this.setState({
                    modulo_content: <div className="text" style={{ color: "#000" }}>{this.dados[0][0][this.state.pergunta - 1]}</div>
                })
                break;
            case 2:
                this.setState({
                    modulo_content: <div className="circle" style={{ backgroundColor: this.getColor(this.dados[1][0][this.state.pergunta - 1]) }}></div>
                })
                break;
            case 3:
                this.setState({
                    modulo_content: <div className="text" style={{ color: this.getColor(this.dados[2][0][this.state.pergunta - 1]) }}>{this.dados[2][1][this.state.pergunta - 1]}</div>
                })
                break;
        }

        //timer para finalizar resposta
        setTimeout(() => { this.loopInterval() }, this.timeToChange);
    }

    finalizarModulo() {

        this.setState({
            isLoading: true
        });

        //envia dados para servidor
        api.post("/teste-remoto/" + this.state.remote_code + "/cadastra-modulo-processando/" + this.state.modulo, { audio: this.audio, respostas: this.respostas, remote_pass: this.state.remote_pass })
            .then((response) => {
                if (response.data.status === "success") {
                    if (this.state.modulo < 3) {
                        //redireciona
                        window.location.href = process.env.PUBLIC_URL + "/teste-remoto/" + this.state.remote_code + "/iniciar/" + (this.state.modulo + 1);
                    }
                    else {
                        this.setState({
                            isLoading: false,
                            modal: <ModalRemover onlymessage={true} message="Teste remoto concluído com sucesso. Obrigado por participar!" />
                        });

                        //apaga dados do teste remoto do localstorage
                        localStorage.removeItem("remote_code");
                        localStorage.removeItem("remote_pass");
                    }

                    //limpa dados
                    this.respostas = [];
                }
                else {
                    console.log(response.data);

                    this.setState({
                        isLoading: false,
                        modal: <ModalRemover onlymessage={true} message="Ocorreu um problema ao enviar os dados. Por favor, tente novamente!" />
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    isLoading: false,
                    modal: <ModalRemover onlymessage={true} message="Ocorreu um problema ao enviar os dados. Por favor, tente novamente!" />
                });

                console.log(err);
            });
    }

    iniciaTeste() {
        this.setState({
            screen: 4,
            teste: 1,
            mostra_resposta: true,
            pergunta: Math.floor(Math.random() * 24) + 1
        });

        this.setaPalavra();

        setTimeout(() => this.setState({ mostra_resposta: false }), this.time);
    }

    proximoTeste() {
        if (this.state.teste >= 4) {
            this.finalizarTreino();
        }
        else {
            this.setState({
                screen: 4,
                teste: this.state.teste + 1,
                mostra_resposta: true,
                pergunta: Math.floor(Math.random() * 24) + 1
            });

            this.setaPalavra();

            setTimeout(() => this.setState({ mostra_resposta: false }), this.time);
        }
    }

    finalizarTreino() {
        this.setState({
            screen: 1,
            pergunta: 1,
            fez_teste: true,
            mostra_resposta: true
        });
    }

    loopInterval() {
        if (this.state.screen === 1) {
            // this.inicia();
        }
        else if (this.state.screen === 2) {
            this.progresso();
        }
        else if (this.state.screen === 3) {
            //verifica se não era a ultima pergunta
            if (this.state.pergunta >= this.state.n_perguntas) {
                //finaliza módulo
                this.finalizarTeste();
            }
            else {
                //vai para a proxima pergunta
                this.proxima(0);
            }
        }
        else if (this.state.screen === 4) {
            this.proximoTeste();
        }
    }

    getColor(color) {
        switch (color) {
            default:
            case "AMARELO":
                return "yellow";
            case "AZUL":
                return "blue";
            case "VERDE":
                return "green";
            case "VERMELHO":
                return "red";
        }
    }

    /**
     * Valida teste
     */
    validaTeste() {

        this.setState({
            isLoading: true
        });

        api.post(`/teste-remoto/${this.state.remote_code}/validar-senha`, { remote_pass: this.state.remote_pass })
            .then((response) => {
                //desativa loading
                this.setState({
                    isLoading: false
                });

                if (response.data.status !== "success") {
                    //exibe erro com mensagem do retorno
                    this.setState({
                        modal: <ModalRemover onlymessage={true} message={response.data.messages[0]} />
                    });
                }
            })
            .catch((err) => {
                //desativa loading
                this.setState({
                    isLoading: false,
                    modal: <ModalRemover onlymessage={true} message="Ocorreu um erro ao tentar validar o teste remoto. Por favor, tente novamente!" />
                });
            });
    }

    render() {
        var progresso = [];
        for (var i = 1; i <= 24; i++) {
            progresso.push(
                <div key={i} className={`item-progresso ${this.state.pergunta > i ? `item-progresso-checked` : ``}`}>{i}</div>
            );
        }

        var modulo_title = null;
        switch (this.state.modulo) {
            default:
            case 1:
                modulo_title = "Leia em voz alta, o mais rápido que conseguir, as PALAVRAS que vão aparecer nas telas seguintes.";
                break;
            case 2:
                modulo_title =  <>Fale em voz alta, o mais rápido que conseguir, o nome da COR dos círculos que vão aparecer nas telas seguintes. {(!this.state.fez_teste ? <><br /><br />Primeiro, vamos treinar!</> : <><br /><br />Agora, vamos iniciar o teste.</>)}</>;
                break;
            case 3:
                modulo_title = <>Fale em voz alta, o mais rápido que conseguir, o nome da COR em que estão escritas as palavras que vão aparecer nas telas seguintes. Lembre-se: Não leia as palavras, fale a cor em que estão escritas. {(!this.state.fez_teste ? <><br /><br />Primeiro, vamos treinar!</> : <><br /><br />Agora, vamos iniciar o teste.</>)}</>;
                break;
        }

        return (
            <div id="teste" tabIndex="0">
                {this.state.screen === 1 ?
                    <div className="tutorial-test">
                        <div className="title-test">Módulo {this.state.modulo}</div>
                        <div className="desc-test">
                            {modulo_title}
                        </div>

                        {(!this.state.fez_teste && this.state.modulo > 1) ?
                            <Button onClick={this.iniciaTeste}>Treinar</Button>
                            :
                            <div className="btn-iniciar" onClick={this.inicia}><FaMicrophone /></div>
                        }
                    </div>
                    : ""
                }

                {this.state.screen === 2 ?
                    <div className="content-test">
                        {this.state.mostra_resposta ? this.state.modulo_content : null}
                    </div>
                    : ""
                }

                {this.state.screen === 4 ?
                    <div className="content-test">
                        {this.state.mostra_resposta ? this.state.modulo_content : null}

                        {/* {(this.state.teste < 4 ?
                            <div className="align-buttons">
                                <Button onClick={this.finalizarTreino}>Finalizar Teste</Button>
                                <Button onClick={this.proximoTeste}>Ok</Button>
                            </div>
                            :
                            <div className="align-buttons">
                                <Button onClick={this.finalizarTreino}>Finalizar Teste</Button>
                            </div>
                        )} */}

                    </div>
                    : ""
                }

                <ReactMic
                    record={this.state.record}
                    className="sound-wave"
                    onStop={this.onStop}
                    onData={this.onData}
                    strokeColor="#FFF"
                    sampleRate={24000}
                    backgroundColor="#198FE7"
                    channelCount={1} />

                {this.state.isLoading ? <Loading /> : null}

                {this.state.modal}

            </div>
        );
    }

}

export default withRouter(TesteRemotoIniciar);