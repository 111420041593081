var contentTest = [
    [
        [
            "AMARELO",
            "AZUL",
            "VERDE",
            "VERMELHO",
            "VERDE",
            "AMARELO",
            "VERMELHO",
            "AZUL",
            "VERMELHO",
            "VERDE",
            "AZUL",
            "AMARELO",
            "AZUL",
            "VERMELHO",
            "AMARELO",
            "VERDE",
            "VERMELHO",
            "AMARELO",
            "VERDE",
            "AZUL",
            "VERDE",
            "AZUL",
            "AMARELO",
            "VERMELHO"
        ]
    ],
    [
        [
            "VERMELHO",
            "VERDE",
            "AZUL",
            "AMARELO",
            "VERDE",
            "AMARELO",
            "VERMELHO",
            "AZUL",
            "AMARELO",
            "AZUL",
            "VERDE",
            "VERMELHO",
            "AZUL",
            "VERMELHO",
            "AMARELO",
            "VERDE",
            "VERDE",
            "AMARELO",
            "AZUL",
            "VERMELHO",
            "AZUL",
            "VERMELHO",
            "VERDE",
            "AMARELO",
        ]
    ],
    [
        [
            "AZUL",
            "AMARELO",
            "VERMELHO",
            "VERDE",
            "AZUL",
            "VERDE",
            "VERMELHO",
            "AMARELO",
            "VERMELHO",
            "VERDE",
            "AMARELO",
            "AZUL",
            "VERDE",
            "AMARELO",
            "AZUL",
            "VERMELHO",
            "VERMELHO",
            "VERDE",
            "AMARELO",
            "AZUL",
            "VERDE",
            "AZUL",
            "VERMELHO",
            "AMARELO",

        ],
        [
            "AMARELO",
            "AZUL",
            "VERDE",
            "AMARELO",
            "VERDE",
            "VERMELHO",
            "AMARELO",
            "AZUL",
            "AZUL",
            "AMARELO",
            "VERMELHO",
            "VERDE",
            "VERMELHO",
            "VERDE",
            "AMARELO",
            "AZUL",
            "AMARELO",
            "AZUL",
            "VERDE",
            "VERMELHO",
            "AMARELO",
            "VERDE",
            "AZUL",
            "VERMELHO",
        ]
    ]
];

export default contentTest;