import React from 'react';
import styles from './style.module.css';
import { IoMdClose } from 'react-icons/io';
import Alert from '../Alert';
import CopyInput from '../CopyInput';

export default function ModalNovoTeste(props) {
    return (
        <div id="modal-remover">
            <div className="content-modal-remover">
                <div className="close-modal-remover" onClick={props.onClick}><IoMdClose /></div>
                <div className="title-remover">
                    Teste Remoto
                </div>

                {(props.error !== null && props.error !== undefined) &&
                    <Alert tipo="error" message={props.error} />
                }

                {props.link !== "" &&
                    <div className={styles.content_modal_teste}>
                        <div className={styles.header_content}>
                            Link:
                        </div>
                        <CopyInput value={props.link} />
                    </div>
                }

                {props.pass !== "" &&
                    <div className={styles.content_modal_teste}>
                        <div className={styles.header_content}>
                            Senha para acesso:
                        </div>
                        <CopyInput value={props.pass} />
                    </div>
                }

            </div>
        </div>
    );
}