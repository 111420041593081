import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import CheckAuth from './services/auth';
import Loading from './components/Loading';

import './assets/styles/global.css';

class AppPesquisador extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      authenticated: true
    }
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.onRouteChanged();
    });

    this.onRouteChanged();
  }

  componentWillUnmount() {
    this.unlisten();
  }

  onRouteChanged(location) {
    this.setState({ isLoading: true });

    CheckAuth()
      .then((response) => {
        this.setState({ isLoading: false, authenticated: response });
      })
      .catch((err) => {
        this.setState({ isLoading: false, authenticated: false });
      });
  }

  render() {
    return (
      <div>
        {this.props.children}
        {this.state.isLoading ? <Loading /> : null}

        {!this.state.authenticated ? <Redirect to={`${process.env.PUBLIC_URL}/restrito`} /> : null }
      </div>
    );
  }
}

export default withRouter(AppPesquisador);