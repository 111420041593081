import React from 'react';
import {FaFacebookF, FaLinkedinIn, FaTwitter} from 'react-icons/fa';

import './style.css';

class Footer extends React.Component {

    render() {
        return (
            <footer id="footer">
                <div className="redes">
                    <a href="#" target="_blank" className="rede"><FaTwitter /></a>
                    <a href="#" target="_blank" className="rede"><FaLinkedinIn /></a>
                    <a href="#" target="_blank" className="rede"><FaFacebookF /></a>
                </div>
                <div className="text-footer">
                    Memnon 2020
                </div>

            </footer>
        );
    }
}

export default Footer;