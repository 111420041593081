import React, { Component } from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TopPage from '../../components/TopPage';
import ListPesquisadores from '../../components/ListPesquisadores';

class AdminDashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            pesquisadores: props.admin !== undefined ? false : true
        }
    }

    componentDidMount() {
        document.title = `Dashboard`;
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <Header />

                <div className="content">
                    <TopPage title={this.state.pesquisadores ? "PESQUISADORES CADASTRADOS" : "ADMINISTRADORES" } add={this.state.pesquisadores ? `${process.env.PUBLIC_URL}/cadastrar-pesquisador` : `${process.env.PUBLIC_URL}/cadastrar-administrador` } />
                    <ListPesquisadores pesquisadores={this.state.pesquisadores} />
                </div>

                <Footer />
            </div>
        );
    }

}

export default AdminDashboard;