import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TopPage from '../../components/TopPage';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Alert from '../../components/Alert';
import Loading from '../../components/Loading';
import Select from '../../components/Select';

import './style.css';



class CadastroAdministrador extends Component {

    constructor(props) {
        super(props)

        this.state = {
            id: (props.match.params.id !== undefined ? props.match.params.id : null),
            nome: "",
            email: "",
            password: "",
            confirma_senha: "",
            status: 1,
            successMessages: [],
            errorMessages: [],
            isLoading: false,
            token: localStorage.getItem("token")
        }
        console.log(this.state.id);

        this.cadastrar = this.cadastrar.bind(this);
        this.atualizar = this.atualizar.bind(this);
    }

    componentDidMount() {
        document.title = `Cadastro de administrador`;
        window.scrollTo(0, 0);

        if (this.state.id !== null) {
            this.carregarDados();
        }
    }

    cadastrar(e) {
        e.preventDefault();
        window.scrollTo(0, 0);

        //ativa loading
        this.setState({
            isLoading: true,
            successMessages: [],
            errorMessages: []
        });

        api.post("/cadastra-admin", this.state)
            .then((response) => {
                //esconde loading
                this.setState({
                    isLoading: false
                });

                if (response.data.status === "success") {
                    //mostra mensagem
                    this.setState({
                        successMessages: response.data.messages,
                        id: response.data.id
                    });
                }
                else {
                    this.setState({
                        errorMessages: response.data.messages
                    });
                }
            })
            .catch((err) => {
                //esconde loading
                this.setState({
                    isLoading: false,
                    errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
                });
            });
    }

    atualizar(e) {
        e.preventDefault();
        window.scrollTo(0, 0);

        if (this.state.id === null) {
            this.setState({
                errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
            });

            return;
        }

        //ativa loading
        this.setState({
            isLoading: true,
            successMessages: [],
            errorMessages: []
        });

        api.put("/atualiza-admin/" + this.state.id, this.state)
            .then((response) => {
                //esconde loading
                this.setState({
                    isLoading: false
                });

                if (response.data.status === "success") {
                    //mostra mensagem
                    this.setState({
                        successMessages: response.data.messages
                    });
                }
                else {
                    this.setState({
                        errorMessages: response.data.messages
                    });
                }
            })
            .catch((err) => {
                //esconde loading
                this.setState({
                    isLoading: false,
                    errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
                });
            });
    }

    carregarDados() {
        //ativa loading
        this.setState({
            isLoading: true,
            successMessages: [],
            errorMessages: []
        });

        api.get("/get-admin/" + this.state.id, { params: { token: localStorage.getItem("token") } })
            .then((response) => {
                //esconde loading
                this.setState({
                    isLoading: false
                });

                if (response.data.status === "success") {
                    //mostra mensagem
                    this.setState(response.data.dados);
                }
                else {
                    this.setState({
                        id: null,
                        errorMessages: response.data.messages
                    });
                }
            })
            .catch((err) => {
                //esconde loading
                this.setState({
                    isLoading: false,
                    id: null,
                    errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
                });
            });
    }

    render() {
        return (
            <div>
                <Header />

                <div className="content">
                    <TopPage title={this.state.id === null ? "NOVO ADMINISTRADOR" : "ATUALIZAR ADMINISTRADOR"} back={`${process.env.PUBLIC_URL}/list-administradores`} />

                    <form id="cadastro-pesquisador" method="POST" onSubmit={(e) => { this.state.id !== null ? this.atualizar(e) : this.cadastrar(e) }}>
                        {this.state.successMessages.length > 0 ? <Alert tipo="success" messages={this.state.successMessages} /> : null}
                        {this.state.errorMessages.length > 0 ? <Alert tipo="error" messages={this.state.errorMessages} /> : null}


                        <Input name="nome" value={this.state.nome} id="nome" placeholder="Nome Completo" onChange={(e) => { this.setState({ nome: e.target.value }) }} />

                        <Input label="Credenciais de acesso" name="email" value={this.state.email} type="email" id="email" placeholder="Email" onChange={(e) => { this.setState({ email: e.target.value }) }} />
                        <Input name="senha" value={this.state.password} type="password" id="senha" placeholder="Senha" onChange={(e) => { this.setState({ password: e.target.value }) }} />
                        <Input name="repetir_senha" value={this.state.confirma_senha} type="password" id="repetir_senha" placeholder="Repetir Senha" onChange={(e) => { this.setState({ confirma_senha: e.target.value }) }} />

                        <Select name="status" value={this.state.status} placeholder="Status da conta" options={[["1", "Acesso liberado"], ["0", "Bloqueado"]]} id="status" onChange={(e) => { this.setState({ status: e.target.value }) }} />

                        <Button id="enviar" className="full" onClick={(e) => { this.state.id !== null ? this.atualizar(e) : this.cadastrar(e) }}>{this.state.id === null ? "Cadastrar" : "Atualizar"}</Button>
                        <Link to={`${process.env.PUBLIC_URL}/list-administradores`} className="cancelar">cancelar</Link>
                    </form>

                    {this.state.isLoading ? <Loading /> : null}
                </div>

                <Footer />
            </div>
        );
    }

}

export default withRouter(CadastroAdministrador);