import React from 'react';
import ReactInputMask from 'react-input-mask';

import './style.css';

class Input extends React.Component {

    render() {
        var label = "";
        if (this.props.label !== undefined) {
            label = <label htmlFor={this.props.name}>{this.props.label}</label>;
        }

        const {button, children, ...rest} = this.props;

        return (
            <div className="input">
                {label}
                <div className="input-relative">
                    <ReactInputMask mask={this.props.mask} onChange={this.props.onChange} type={this.props.type} id={this.props.name} name={this.props.name} placeholder={this.props.placeholder} placeholdertextcolor="#939393" {...rest} />
                    <div className="button-float">
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

export default Input;