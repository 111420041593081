import React from 'react';

import './style.css';
import { Link } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { FaUserPlus, FaPlus, FaPen, FaFileExcel } from 'react-icons/fa';
import Button from '../Button';
import ReactTooltip from 'react-tooltip';

class TopPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            show_teste_modal: (props.show_teste_modal ? props.show_teste_modal : false)
        };
    }

    render() {
        var back = null;
        if (this.props.back !== undefined) {
            back = <Link data-tip="Voltar" className="back-icon" to={this.props.back}><IoMdArrowRoundBack /></Link>;
        }

        var add = null;
        if (this.props.add !== undefined) {
            add = <Link data-tip="Adicionar novo" className="add-icon" to={this.props.add}><FaUserPlus /></Link>;
        }

        var addteste = null;
        if (this.props.addteste !== undefined) {
            addteste = <button data-tip="Novo teste" className="add-icon edit-icon export-icon" onClick={(e) => this.setState({ show_teste_modal: !this.state.show_teste_modal })} >
                <FaPlus />

                {this.state.show_teste_modal &&
                    <div className="create-test">
                        <div className="opcoes-teste">
                            <div className="tipo-teste">Tipo de teste:</div>
                            <Button onClick={this.props.addteste}>Local</Button>
                            {this.props.addtesteremoto !== undefined ? <Button onClick={this.props.addtesteremoto}>Remoto SEM senha</Button> : null}
                            {this.props.addtesteremotosemsenha !== undefined ? <Button onClick={this.props.addtesteremotosemsenha}>Remoto COM senha</Button> : null}
                        </div>
                    </div>
                }
            </button>;
        }

        var editpaciente = null;
        if (this.props.editpaciente !== undefined) {
            editpaciente = <Link data-tip="Editar paciente" className="add-icon edit-icon" to={this.props.editpaciente}><FaPen /></Link>;
        }
        var exportxls = null;
        if (this.props.exportxls !== undefined) {
            exportxls = <a data-tip="Exportar XLS" className="add-icon" href={this.props.exportxls} target="_blank"><FaFileExcel /></a>;
        }

        var small = null;
        if (this.props.small !== undefined) {
            small = <small>{this.props.small}</small>;
        }

        var small2 = null;
        if (this.props.small2 !== undefined) {
            small2 = <small>{this.props.small2}</small>;
        }

        var small3 = null;
        if (this.props.small3 !== undefined) {
            small3 = <small>{this.props.small3}</small>;
        }

        var small4 = null;
        if (this.props.small4 !== undefined) {
            small4 = <small>{this.props.small4}</small>;
        }


        var icon = null;
        if (this.props.icon !== undefined) {
            icon = <i>{this.props.children}</i>;
        }

        return (
            <header id="top-page">
                <div className="container">
                    {back}
                    <h1>
                        {icon}
                        {this.props.title}
                        {small}
                        {small2}
                        {small3}
                        {small4}
                    </h1>
                    {add}
                    {exportxls}
                    {editpaciente}
                    {addteste}
                </div>

                {this.state.show_teste_modal &&
                    <div className="create-test-bg" onClick={(e) => this.setState({ show_teste_modal: false })}></div>
                }

                <ReactTooltip place='bottom' effect='solid' />
            </header>
        );
    }
}

export default TopPage;