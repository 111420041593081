import React, { Component } from 'react';
import { IoMdSearch } from 'react-icons/io';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TopPage from '../../components/TopPage';
import Input from '../../components/Input';
import Button from '../../components/Button';
import ListPacientes from '../../components/ListPacientes';
import ModalRemover from '../../components/ModalRemover';

class PesquisadorDashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            busca_nome: "",
            modal: null
        }
    }

    componentDidMount() {
        document.title = `Dashboard`;
        window.scrollTo(0, 0);

        this.iniciaMicrofone();
    }

    iniciaMicrofone() {
        try {
            if (navigator.mediaDevices.getUserMedia !== null) {
                var options = {
                    video: false,
                    audio: true
                };
                navigator.mediaDevices.getUserMedia(options)
                    .then((stream) => {
                        //para stream
                        stream.getTracks().forEach(x => x.stop());
                    })
                    .catch((err) => {
                        this.setState({
                            modal: <ModalRemover onClick={() => (this.setState({ modal: null }))} onlymessage={true} message="É preciso dar permissão ao microfone para continuar" />
                        });
                    });
            }
            else {
                this.setState({
                    modal: <ModalRemover onClick={() => (this.setState({ modal: null }))} onlymessage={true} message="Nenhum microfone encontrado ou seu navegador não tem permissão para acessa-lo." />
                });
            }
        }
        catch (e) {
            this.setState({
                modal: <ModalRemover onClick={() => (this.setState({ modal: null }))} onlymessage={true} message="Nenhum microfone encontrado ou seu navegador não tem permissão para acessa-lo." />
            });
        }
    }

    render() {
        return (
            <div>
                <Header />

                <div className="content">
                    <TopPage title="PACIENTES" add={`${process.env.PUBLIC_URL}/cadastrar-paciente`} />

                    <Input label="Buscar paciente" placeholder="Nome do paciente" name="paciente" id="paciente" button={true} onChange={(e) => { this.setState({ busca_nome: e.target.value }) }}><Button><IoMdSearch /></Button></Input>

                    <ListPacientes nome={this.state.busca_nome} />
                </div>

                <Footer />

                {this.state.modal}
            </div>
        );
    }

}

export default PesquisadorDashboard;